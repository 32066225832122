/**
 * Loading
 *
 */
import React, { Component } from 'react';

// Fonts
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';


/* Component ==================================================================== */
class Loading extends Component {
  static componentName = 'Loading';

  render = () => {
    return (
      <div className="loading site-wrapper site-wrapper-absolute">
        <div className="site-wrapper-inner">
          <div className="cover-container text-center">
            <h4><FontAwesomeIcon icon={faSpinner} /> Loading...</h4>
          </div>
        </div>
      </div>
    );
  };
}


/* Export Component ==================================================================== */
export default Loading;
