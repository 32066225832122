/**
 *
 * Payments
 * Payments informaion
 */

import React, { Component } from 'react';
import { connect } from 'react-redux';
import Helmet from 'react-helmet';
import PropTypes from 'prop-types';

// Consts and Libs
import AppAPI from '../lib/api';
import Utls from '../lib/util';
import { ErrorMessages } from '../constants/';

// Components
import Loading from '../components/general/Loading';
import NotFound from './NotFound';


// Font-Awesome Icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import { faCircle } from '@fortawesome/free-regular-svg-icons';
import { faCcAmex, faCcMastercard, faCcVisa } from '@fortawesome/free-brands-svg-icons';

// Actions
import * as PaymentAction from '../redux/payment/actions';

/* Redux ==================================================================== */
// What data from the store shall we send to the component?
const mapStateToProps = (state) => ({
  payment: state.payment.payment,
});

// Any actions to map to the component?
const mapDispatchToProps = {
  setPayment: PaymentAction.setPayment,
  checkPaymentStatus: PaymentAction.checkPaymentStatus
};


/* Component ==================================================================== */
class Payments extends Component {
  static componentName = 'Payments';

  static propTypes = {
    match: PropTypes.object.isRequired,
  };

  componentDidMount = () => {
    console.log('asdasd');
    console.log(this.props.setPayment);
    if (this.props.match && this.props.match.params.PaymentId) {

      console.log('asas');
      this.props.setPayment(this.props.match.params.PaymentId)
        .then(() => {
          console.log('hello here');
          this.setState({
            loading: false,
          });
        })
        .catch((err) => {
          const error = AppAPI.handleError(err);
          this.setState({
            loading: false,
            error,
          });
        });
    } else {
      this.setState({
        loading: false,
        error: true
      });
    }
  };

  setGateway = (gateway) => {
    this.setState({selectGateway: gateway});
  };

  checkPaymentStatus = () => {
    this.setState({paymentStatusLoading: true});

    this.props.checkPaymentStatus(this.props.match.params.PaymentId)
      .then(() => {
        this.setState({
          paymentStatusLoading: false,
        });
      })
      .catch((err) => {
        const error = AppAPI.handleError(err);
        this.setState({
          paymentStatusLoading: false,
          error,
        });
      });
  };


  render = () => {

    const {loading, error, selectGateway, paymentStatusLoading} = this.state;
    const {payment} = this.props;

    if (loading) return <Loading/>;
    if (error) return <NotFound full={true} text={ErrorMessages.notfound}/>;


    let PAYMENT_GATEWAY = <div className={'alert alert-warning'}>
      <h6 className={'alert-heading'}>Error!</h6>
      <p className={'small'}>Payments cannot be processed at the moment.</p>
      <hr/>
      <p className={'small'}>Please contact your vendor.</p>
    </div>;

    if (payment.accept_payment) {
      if (Utls.objIsEmpty(payment.gateway) !== true) {

        PAYMENT_GATEWAY = <div>
          {(payment.payment_status === -1) && <div className={'alert alert-danger'}>
            <h6 className={'alert-heading'}>
              <FontAwesomeIcon icon={faCheckCircle} className={'mr-1'} size={'sm'}/> Payment Failed
            </h6>
            <p className={'small'}>Previous payment failed, please try again.</p>
          </div>
          }
          <div className={'border rounded p-3'}>
            <p className="text-uppercase small text-secondary">Pay Using</p>
            <ul className={'list-inline'}>
              {Object.keys(payment.gateway).map((data, key) => (
                <li key={key} className={'list-inline-item'}>
                  <button
                    className={'btn ' + (selectGateway === data ? 'btn-outline-success' : 'btn-outline-secondary')}
                    onClick={() => this.setGateway(data)}>
                    <FontAwesomeIcon
                      icon={(selectGateway === data ? faCheckCircle : faCircle)}
                      className={'mr-1'}/>{data}
                  </button>
                </li>
              ))}
            </ul>
            <form action={(selectGateway ? payment.gateway[selectGateway].action_url : '')} method={'POST'}>
              {
                selectGateway && Object.keys(payment.gateway[selectGateway]).map((data, key) => (
                  <input type="hidden" name={data} key={key} value={payment.gateway[selectGateway][data]}/>
                ))
              }
              <button
                className={'btn btn-success btn-large btn-block ' + (selectGateway === null && 'disabled')}>
                Pay Securely Now
              </button>
            </form>
          </div>
        </div>;
      }
    } else {
      if (payment.payment_status === 1) {
        PAYMENT_GATEWAY = <div className={'alert alert-success'}>
          <h6 className={'alert-heading'}>
            <FontAwesomeIcon icon={faCheckCircle} className={'mr-1'} size={'sm'}/> Payment Complete
          </h6>
          <hr/>
          <p className={'small'}>We have received {payment.currency} {payment.total}.</p>
        </div>;

      } else if (payment.payment_status === 2) {
        PAYMENT_GATEWAY = <div className={'alert alert-warning'}>
          <h6 className={'alert-heading'}>Pending</h6>
          <p className={'small'}>We are awaiting for payment status from your bank.</p>
          <p className={'small'}>Order status will stay as not paid until we get a response from bank.</p>
          <button
            onClick={() => this.checkPaymentStatus()}
            className={'btn btn-success btn-sm'}>
            Check Status
          </button>
        </div>;
      }
    }

    return (
      <div className="index screen-container site-wrapper site-wrapper-absolute">
        <Helmet>
          <title>
            {(payment.get_payment_status_display && '[' + payment.get_payment_status_display + '] ')} Payment for
            #{payment.invoice_no}</title>
        </Helmet>

        <div className="site-wrapper-inner">
          <div className="cover-container">
            <div className={'row'}>
              <div className={'col-xl-6 col-lg-7 col-md-9 col-sm-10 mx-auto'}>
                <div className={' border rounded p-4'}>
                  <h4>Payment Request form {payment.payment_request_from}</h4>
                  <hr className={'mb-4'}/>
                  <div className="d-md-flex flex-md-equal">
                    <div className=" mr-md-3 overflow-hidden align-self-center">
                      <p className="text-uppercase small text-secondary pb-0 mb-0">Invoice</p>
                      <p>{payment.invoice_no}</p>

                      <p className="text-uppercase small text-secondary pb-0 mb-0">Payment For Order</p>
                      <p>{payment.payment_request_for}</p>

                      <p className="text-uppercase small text-secondary pb-0 mb-0">Payment Amount</p>
                      <h4>{payment.currency} {payment.total}</h4>
                    </div>

                    <div className=" mr-md-3 pt-3 pt-md-5 overflow-hidden align-self-center position-relative">
                      {paymentStatusLoading ? <Loading/> : PAYMENT_GATEWAY}
                    </div>
                  </div>
                </div>

                <div className={'row mt-4'}>
                  <div className="col-lg-6 col-md-6 col-sm-10 mx-auto">
                    <ul className={'list-inline text-md-left text-center brand'}>
                      <li className={'list-inline-item'}>
                        <FontAwesomeIcon icon={faCcMastercard} size={'2x'}/>
                      </li>

                      <li className={'list-inline-item'}>
                        <FontAwesomeIcon icon={faCcVisa} size={'2x'}/>
                      </li>

                      <li className={'list-inline-item'}>
                        <FontAwesomeIcon icon={faCcAmex} size={'2x'}/>
                      </li>
                    </ul>
                  </div>
                  <div className="col-lg-6 col-md-6 col-sm-10 mx-auto">
                    <div className="text-md-right text-center brand">
                      <small>Powered by</small>
                      <br/>
                      <img alt={'Renzo'} src={require('../images/logo.png')}/>
                    </div>
                  </div>
                </div>

              </div>
            </div>


          </div>
        </div>
      </div>
    );
  };

  constructor (props) {
    super(props);

    this.state = {
      loading: true,
      paymentStatusLoading: false,
      error: false,
      selectGateway: null
    };
  }
}


export default connect(mapStateToProps, mapDispatchToProps)(Payments);