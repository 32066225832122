/**
 * Payment Actions
 *
 */
import AppAPI from '../../lib/api';


/**
 * Set Payment
 */
export const setPayment = (paymentData) => (dispatch) => new Promise(async (resolve, reject) => {
  if (typeof paymentData === 'object') {
    dispatch({
      type: 'SET_PAYMENT',
      data: paymentData,
    });
    return resolve(paymentData);
  } else if (paymentData) {
    // Fetch Payment Data
    return AppAPI.preauth.get(paymentData + '/')
      .then((res) => {
        // 200 response its permitted
        dispatch({
          type: 'SET_PAYMENT',
          data: res,
        });
        return resolve(res);
      })
      .catch(err => reject(err));
  }

  return reject();
});

/**
 * Update Payment
 */
export const updatePayment = (paymentData) => setPayment(paymentData);

/**
 * Remove Payment
 */
export function unSetPayment () {
  return (dispatch) => {
    dispatch({
      type: 'SET_PAYMENT',
      data: null,
    });
  };
}


/**
 * Set Payment
 */
export const checkPaymentStatus = (paymentData) => (dispatch) => new Promise(async (resolve, reject) => {
  if (typeof paymentData === 'object') {
    dispatch({
      type: 'SET_PAYMENT',
      data: paymentData,
    });
    return resolve(paymentData);
  } else if (paymentData) {
    // Fetch Payment Data
    return AppAPI.status.get(paymentData + '/')
      .then((res) => {
        // 200 response its permitted
        dispatch({
          type: 'SET_PAYMENT',
          data: res,
        });
        return resolve(res);
      })
      .catch(err => reject(err));
  }

  return reject();
});