/**
 *
 * Payments Index
 * Small info abt payments to users
 */

import React, { Component } from 'react';
import Helmet from 'react-helmet';

// Font-Awesome Icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMoneyCheckAlt } from '@fortawesome/free-solid-svg-icons';

// Components
import Loading from '../components/general/Loading';
import Error from '../components/general/Error';


/* Component ==================================================================== */
class Index extends Component {
  static componentName = 'Index';

  render = () => {

    const {loading, error} = this.state;

    if (loading) return <Loading/>;
    if (error) return <Error text={error}/>;

    return (
      <div className="index screen-container site-wrapper site-wrapper-absolute">
        <Helmet>
          <title>Payments</title>
          <meta
            name="description"
            content="Renzo uses payments.renzo.in as a part of service to facilitate payments."/>
        </Helmet>

        <div className="site-wrapper-inner">
          <div className="cover-container row">
            <div className="col-lg-6 col-md-8 col-sm-10 mx-auto">
              <h1>
                <FontAwesomeIcon className={'green-cl'} icon={faMoneyCheckAlt} size={'sm'}/> Renzo Payments
              </h1>
              <p className={'lead'}>only payments accepted here :)</p>
              <p>Renzo uses payments.renzo.in as a part of service to facilitate payments.</p>
              <p>Payment links are issued by our partners to collect payments, for any other information go
                to <a href="https://renzo.in">renzo.in</a>.</p>

              <div>
                <div className="text-md-right text-center brand">
                  <img alt={'Renzo'} src={require('../images/logo.png')}/>
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>
    );
  };

  constructor (props) {
    super(props);

    this.state = {
      loading: false,
      error: false,
    };
  }
}


export default Index;