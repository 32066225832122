/**
 *
 * Features Booking
 * *
 */

import React, { Component } from 'react';
import Helmet from 'react-helmet';

// Components
import Loading from '../components/general/Loading';
import Error from '../components/general/Error';

// Font-Awesome Icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimesCircle } from '@fortawesome/free-solid-svg-icons';


/* Component ==================================================================== */
class Index extends Component {

  showEmailAddress = () => {
    this.setState({showEmail: true});
  };

  render = () => {

    const {loading, error, showEmail} = this.state;

    if (loading) return <Loading/>;
    if (error) return <Error text={error}/>;

    return (
      <div className="index screen-container site-wrapper site-wrapper-absolute">
        <Helmet>
          <title>Its an error!</title>
          <meta name="description" content="Payment request failed."/>
        </Helmet>

        <div className="site-wrapper-inner">
          <div className="cover-container row">
            <div className="col-lg-6 col-md-8 col-sm-10 mx-auto">
              <h1 className={''}><FontAwesomeIcon icon={faTimesCircle} className={'red-cl'}/> Failed !</h1>
              <p className={'lead'}>Hmmm, something didn't work</p>
              <p>You are seeing this page because the payment request was cancelled or failed unexpectedly at our
                payment gateway.</p>
              <p>Please contact {showEmail === true ? <span>support@renzo.in</span> :
                <span onClick={() => this.showEmailAddress()} className={'ul'}><u>Click to See email
                        address.</u></span>} for more information. </p>
              <a href="https://renzo.in" className="a">Go to Renzo</a>

              <div>
                <div className="text-md-right text-center brand">
                  <img alt={'Renzo'} src={require('../images/logo.png')}/>
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>
    );
  };

  constructor (props) {
    super(props);

    this.state = {
      loading: false,
      error: false,
      showEmail: false,
    };
  }
}


export default Index;