/**
 * Global App Config
 *
 */

export default {
  // App Details
  appName: 'Renzo Payments',
  DEV: true
};
