/**
 * API Config
 *
 */

let hostUrl = 'http://api.renzo.in/pgateway/1';

if (process.env.REACT_APP_SERVER_URL) {
  hostUrl = process.env.REACT_APP_SERVER_URL;
}

export default {
  // The URL we're connecting to
  // iOS
  // 'http://192.168.0.200/api/1/pgateway'
  hostname: hostUrl,

  // Android
  // hostname: "http://10.0.2.2:8000/api/1",

  // Map shortnames to the actual endpoints, so that we can
  // use them like so: AppAPI.ENDPOINT_NAME.METHOD()
  //  NOTE: They should start with a /
  //    eg.
  //    - AppAPI.account.get()
  endpoints: new Map([
    ['preauth', '/api/preauth'],
    ['status', '/api/status'],
  ]),

  // Which 'endpoint' key deals with our tokens?
  tokenKey: 'auth',
};
