/**
 *
 * Features Booking
 * *
 */

import React, { Component } from 'react';
import Helmet from 'react-helmet';

// Components
import Loading from '../components/general/Loading';
import Error from '../components/general/Error';


/* Component ==================================================================== */
class Index extends Component {

  render = () => {

    const {loading, error} = this.state;

    if (loading) return <Loading/>;
    if (error) return <Error text={error}/>;

    return (
      <div className="index screen-container site-wrapper site-wrapper-absolute">
        <Helmet>
          <title>Its an error!</title>
          <meta name="description" content="The content you are looking for is not found."/>
        </Helmet>

        <div className="site-wrapper-inner">
          <div className="cover-container row">
            <div className="col-lg-6 col-md-8 col-sm-6 mx-auto text-center">
              <h1> Error !</h1>
              <p className={'lead'}>Hmmm, something didn't work</p>
              <p>You are seeing this page because the payment link you requested does not exist or is cancelled. Please
                contact vendor for information.</p>
              <a href="https://renzo.in" className="a">Go to Renzo</a>
            </div>
          </div>
        </div>
      </div>
    );
  };

  constructor (props) {
    super(props);

    this.state = {
      loading: false,
      error: false,
    };
  }
}


export default Index;