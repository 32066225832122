import React from 'react';
import { Route, Switch } from 'react-router-dom';

//scenes
import Payments from './containers';
//Decorators

const navigator = () => (
  <Switch>
    <Route path="/" component={Payments}/>
  </Switch>
);

export default navigator;